// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'


Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#03A9F4',
                secondary: '#050B1F',
                accent: '#204165',
            },
            dark: {
                primary: '#03A9F4',
                secondary: '#0B1C3D',
                accent: '#204165',
            },
        },
    },
})
