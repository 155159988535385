<template>
	<v-fade-transition mode="out-in">
		<router-view />
	</v-fade-transition>
</template>

<script>
export default {
	name: "App",
	metaInfo: {
		title: "Solar Decathlon China Official Website | 中国国际太阳能十项全能竞赛官方网站",
		/* titleTemplate: "%s | Solar Decathlon China", */
		htmlAttrs: { lang: "zh-CN" },
		meta: [
			{ charset: "utf-8" },
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			{
				name: "description",
				content: "中国国际太阳能十项全能竞赛（Solar Decathlon China, SDC）以全球高校为参赛单位，每个团队设计并建造一栋以太阳能为唯一能源且设计新颖、节能高效、市场认可的高性能全尺寸房屋，并参与十项指标评比，通过竞赛期间的实际运行数据，最终评选出融合完美设计、智慧能源、多样创新和市场潜力的优秀作品。竞赛希望通过评比和展示的形式，加快产学研融合与交流，推进相关技术创新、发展和商业化。",
			},
			{
				name: "keywords",
				content: "Solar Decathlon, Solar Decathlon China, 国际太阳能十项全能竞赛, 中国国际太阳能十项全能竞赛, 太阳能, 绿色建筑, 可持续能源",
			},
			{
				name: "author",
				content: "Organizing Committee of Solar Decathlon China 中国国际太阳能十项全能竞赛组委会",
			},
			{
				property: "og:title",
				content: "Solar Decathlon China Official Website | 中国国际太阳能十项全能竞赛官方网站",
			},
		],
	},
};
</script>
