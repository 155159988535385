import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import i18n from '@/i18n'
import "@/assets/fonts/font.css"

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
